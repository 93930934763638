import React, { useEffect, useState } from "react";
import logoigtwhiteslice from "../../assets/logoigt_white_slice.png";
import profileImg from "../../assets/profile_img.jpg";
import {
  Avatar,
  Badge,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import NotificationsIcon from "@mui/icons-material/Notifications";

import "../../css/header.css";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../features/user/userSlice";
import { useNavigate } from "react-router-dom";

const Header = () => {
  const dispatch = useDispatch();
  const [avatarOption, setAvatarOption] = useState(null);
  const { loading, loggedIn, user, error } = useSelector(state => state.user);
  const navigate = useNavigate();

  const handleOpenMenu = (event, setAnchorEl) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = (setAnchorEl) => {
    setAnchorEl(null);
  };

  const handleSidebar = () => {
    var body = document.body;
    if (window.screen.width <= 998) {
      body.classList.toggle("sidebar-enable");
    } else {
      body.classList.toggle("vertical-collpsed");
      body.classList.toggle("sidebar-enable");
    }
  }

  const handleLogout = () => {
    
    dispatch(logout());
    navigate('/login')
  }

  useEffect(() => {
    if(!localStorage.getItem('user')) {
      navigate('/login')
    }
  }, [user])
  
  

  return (
    <header id="page-topbar">
      <div className="navbar-headers">
        <div className="left-header">
          <div className="navbar-brand-box">
            <a className="logo" href="/">
              <span className="logo-sm">
                <img src={logoigtwhiteslice} alt="" height="22" />
              </span>
            </a>
          </div>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="open drawer"
            sx={{ mr: 2 }}
            onClick={handleSidebar}
          >
            <MenuIcon />
          </IconButton>
        </div>
        <div className="right=header">
          {/* <IconButton
            size="large"
            aria-label="show 4 new mails"
            color="inherit"
          >
            <Badge badgeContent={4} color="error">
              <NotificationsIcon />
            </Badge>
          </IconButton> */}
          <IconButton
            
            onClick={(e) => handleOpenMenu(e, setAvatarOption)}
          >
            <Avatar alt="Remy Sharp" src={''} />
          </IconButton>
          <Menu
            sx={{ mt: "45px" }}
            id="menu-appbar"
            anchorEl={avatarOption}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(avatarOption)}
            onClose={() => handleCloseMenu(setAvatarOption)}
          >
            <MenuItem onClick={handleLogout}>
              <Typography sx={{ textAlign: "center" }}>Logout</Typography>
            </MenuItem>
          </Menu>
        </div>
      </div>
    </header>
  );
};

export default Header;
