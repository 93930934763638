import React, { useEffect, useState } from "react";
import { Avatar, Grid, Stack, Typography } from "@mui/material";
import CardComponent from "../../../components/Card";
import ShoppingBagOutlinedIcon from "@mui/icons-material/ShoppingBagOutlined";
import { useSelector } from "react-redux";
import { postRequest } from "../../../services/api";

const AsnCount = () => {
  const { startDate, endDate } = useSelector((state) => state.dashboard);
  const { user, userSetting } = useSelector((state) => state.user);
  const [asnCount, setAsnCount] = useState([]);

  const formatAsnCount = (data) => {
    let mappedObj = {
      totalASN: {label: "Total ASN", icon:ShoppingBagOutlinedIcon},
      totalVerifiedClosedASN: {label: "Total Verified Closed", icon:ShoppingBagOutlinedIcon},
      totalItemsRcvd: {label: "Total Item Received", icon:ShoppingBagOutlinedIcon},
      totalPiecesRcvd: {label: "Total Pieces Received",icon:ShoppingBagOutlinedIcon} ,
    };
    const formatData = Object.keys(mappedObj).map((item) => {
      const sumData = data.reduce((acc, obj) => {
        acc += parseFloat(obj[item]);
        return acc;
      }, 0);

      return {
        label: mappedObj[item]['label'],
        value: sumData,
        icon: mappedObj[item]['icon']
      };
    });

    setAsnCount(formatData);
  };

  const fetchAsnCount = async () => {
    let headers = {
      "Content-Type": "application/json;",
      Authorization: `Bearer ${user.token}`,
    };
    let payload = {
      custCode: userSetting[0].currCustomer,
      whseCode: userSetting[0].currWarehouse,
      fromDate: startDate.format("YYYY-MM-DD"),
      toDate: endDate.format("YYYY-MM-DD"),
    };
    const response = await postRequest(
      "user/getDashCount_asnReceipts_2",
      payload,
      headers
    );
    formatAsnCount(response.data.Payload);
  }

  useEffect(() => {
    fetchAsnCount();
  }, [startDate, endDate, userSetting]);

  return asnCount.map((item) => (
    <Grid item xs={3}>
      <CardComponent>
        <Stack direction="row" spacing={2}>
          <Avatar sx={{ bgcolor: "#eceaf9", width: 40, height: 40 }}>
            <item.icon sx={{ color: "#a878e6", fontSize: 25 }} />
          </Avatar>
          <div className="card-title-wrap">
            <Typography variant="subtitle1" gutterBottom>
              {item.label}
            </Typography>
            <Typography variant="h5" component="p" sx={{ fontWeight: 700 }}>
              {item.value}
            </Typography>
          </div>
        </Stack>
      </CardComponent>
    </Grid>
  ));
};

export default AsnCount;
