import moment from "moment";
import _ from "lodash";

const groups = (dateKey) => {
    const byDay = (item) => moment(item[dateKey]).format("DD/MM/YYYY"),
      forMonth = (item) => moment(item[dateKey]).format("MM/YYYY"),
      forWeek = (item) => forMonth(item) + " " + moment(item[dateKey]).format("ww"),
      forYear = (item) => moment(item[dateKey]).format("YYYY");
    return {
      byDay,
      forMonth,
      forWeek,
      forYear
    };
  };

  export const sumData = (data, period, dateKey = 'ActualShipDate', totalKey= 'TotalShipped') => {
    data.sort(function (a, b) {
      return new Date(b[dateKey]) - new Date(a[dateKey]);
    });
  
    switch (period) {
      case "forWeek": {
        console.log("Group by week");
        const obj = _.groupBy(data, groups(dateKey)["forWeek"]);
        const array = objectToArray(obj);
        array.sort(function (a, b) {
          return new Date(b[0][dateKey]) - new Date(a[0][dateKey]);
        });
        return sumArray(array, totalKey);
      }
      case "forYear": {
        console.log("Group by year");
        const obj = _.groupBy(data, groups(dateKey)["forYear"]);
        const array = objectToArray(obj);
        array.sort(function (a, b) {
          return new Date(b[0][dateKey]) - new Date(a[0][dateKey]);
        });
        return sumArray(array, totalKey);
      }
      case "forMonth": {
        console.log("Group by month");
        const obj = _.groupBy(data, groups(dateKey)["forMonth"]);
        const array = objectToArray(obj);
        array.sort(function (a, b) {
          return new Date(b[0][dateKey]) - new Date(a[0][dateKey]);
        });
        return sumArray(array, totalKey);
      }
      case "byDay":
        console.log("Group by 24 hours");
        const obj = _.groupBy(data, groups(dateKey)["byDay"]);
        const array = objectToArray(obj);
        array.sort(function (a, b) {
          return new Date(b[0][dateKey]) - new Date(a[0][dateKey]);
        });
        return sumArray(array, totalKey);
      default: {
        console.log("Group by day");
        const obj = _.groupBy(data, groups(dateKey)["byDay"]);
        const array = objectToArray(obj);
        array.sort(function (a, b) {
          return new Date(b[0][dateKey]) - new Date(a[0][dateKey]);
        });
        return sumArray(array, totalKey);
      }
    }
  };

const sumArray = (data, totalKey) => {
    let newArray = [];
  
    data.forEach((arrayItem) => {
      const sumDate = arrayItem.reduce((acc, obj) => {
        acc += parseFloat(obj[totalKey]);
        return acc;
      }, 0);
      const labelDate = arrayItem.reduce((acc, obj) => {
        acc = obj.label;
        return acc;
      }, "");
      newArray.push({
        // color: "Blue",
        value: sumDate,
        label: labelDate
      });
    });
  
    return newArray;
  };

  const objectToArray = (obj) => {
    return _.values(
      _.mapKeys(obj, function (value, key) {
        value.map((item) => (item.label = key));
        return value;
      })
    );
  };