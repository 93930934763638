import React from "react";
import ReactEcharts from "echarts-for-react";

const BarChart = ({ chartName, chartData }) => {
  let option = {
    
    title: {
      text: chartData.length=== 0 ? "No Record Found":"",
     
      left: "center",
      top: "center",
      textStyle: {
        fontSize: 20
      },
    },
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "shadow",
      },
    },
    grid: {
      left: "3%",
      right: "4%",
      bottom: "3%",
      containLabel: true,
    },
    xAxis: [
      {
        type: "category",
        data: chartData.map((item) => item.label),
        axisTick: {
          alignWithLabel: true,
        },
        axisLabel: {
          rotate: 45,
        },
      },
    ],
    yAxis: [
      {
        type: "value",
        axisLabel: {
           interval: 0
          }
      },
    ],
    series: [
      {
        name: "Shipment",
        type: "bar",
        barWidth: "20",
        data: chartData.map((item) => item.value),
      },
    ],
  };
  return <ReactEcharts option={option} />;
};

export default BarChart;
