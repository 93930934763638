import React, { useEffect, useState } from "react";
import { Stack, Typography } from "@mui/material";
import { postRequest } from "../../../services/api";
import { ASN_DATE_WISE_DATA } from "../../../constants";
import BarChart from "../../../components/BarChart";
import { sumData } from "../../../utils";
import { useSelector } from "react-redux";

const DateWiseShipment = () => {
  const { startDate, endDate } = useSelector((state) => state.dashboard);
  const { user, userSetting } = useSelector((state) => state.user);

  const [data, setData] = useState(ASN_DATE_WISE_DATA);
  const [chartData, setChartData] = useState([]);

  const dateWiseFormat = (data) => {
    const arrayResultByDay = sumData(data, "forDay", 'ReceiptDate', 'totReceivedQty');
    setChartData(arrayResultByDay);
  };

  useEffect(() => {
    dateWiseFormat(data);
  }, [data]);


  const fetchDateWiseShipmentData = async () => {
    let headers = {
      "Content-Type": "application/json;",
      Authorization: `Bearer ${user.token}`,
    };
    let payload = {
      custCode: userSetting[0].currCustomer,
      whseCode: userSetting[0].currWarehouse,
      fromDate: startDate.format("YYYY-MM-DD"),
      toDate: endDate.format("YYYY-MM-DD"),
      receiptStatus : "Received",
      receiptType : "all"
    };
    const response = await postRequest(
      "user/getDashData_ASNReceipts",
      payload,
      headers
    );
    setData(response.data.Payload);
  };

  useEffect(() => {
    fetchDateWiseShipmentData();
  }, [startDate, endDate, userSetting]);

  return (
    <div>
      <Stack
        direction="row"
        sx={{ justifyContent: "space-between", alignItems: "center" }}
      >
        <Typography>Date Wise Shipment</Typography>
      </Stack>

      <BarChart chartName={"Date Wise shipment status"} chartData={chartData} />
    </div>
  );
};

export default DateWiseShipment;
