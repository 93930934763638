import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import PrivateRoutes from "components/PrivateRoutes";
import Dashboard from "pages/Dashboard";
import Login from "pages/Login";
import Layout from "components/Layout";
import ASN from "pages/ASN";
import Orders from "pages/Orders";

import { LicenseInfo } from '@mui/x-license';
import Inventory from "pages/Inventory";
LicenseInfo.setLicenseKey('6e590720d0b3b5bfcff3012cf6975310Tz04Nzc2NixFPTE3NDM3OTI4NzEwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y')

function App() {
  return (
    <Router>
      <Routes>
        <Route element={<PrivateRoutes />}>
          <Route element={<Layout />}>
             <Route path="/dashboard" element={<Dashboard />} />
             <Route path="/asn" element={<ASN />} />
             <Route path="/orders" element={<Orders/>} />
             <Route path="/inventory" element={<Inventory />} />
          </Route>
        </Route>
        <Route path="/login" element={<Login />} />
        <Route path="/" element={<Login />} />
      </Routes>
    </Router>
  );
}

export default App;